.sidebar-content {
    width: 260px;
}

.cursor-pointer { 
    cursor: pointer;
}

.w-120 {
    width: 120px;
}

.desc {
    height: 45px;
    max-height: 72px;
}

.loader {
    margin: auto;
    display: block;
}

.dropdown {
    z-index: 3;
}

.infinite-scroll {
    overflow: inherit !important;
}

.theme-preview-image {
    height: 220px !important;
}

.theme-animation {
    height: 15rem;
    width: 20rem;
}

.displayColor {
    display: inline-block;
    border-radius: 3px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    border: 1px solid #b4b4b4;
}

.themeCategoryDropdown {
    z-index: 6;
}

.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
  }
  
  .react-tagsinput--focused {
    color: #495057;
    background-color: #fff;
    border-color: #a6dee1 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
                0 0 0 0.2rem rgba(71, 186, 193, 0.25);
  }
  
  .react-tagsinput-tag {
    background-color: #F5F9FC;
    border-radius: 2px;
    border: 1px solid #47BAC1;
    border-radius: 3px;
    color: #47BAC1;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }
  
  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
  }
  
  .react-tagsinput-tag a::before {
    content: " ×";
  }
  
  .react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 120px !important;
  }

  .importPageCard {
    position: relative;
  }

  .importPageCheckbox {
    position: absolute;
    left: 11%;
    top: 2%;
    z-index: 2;
    height: 24px;
    width: 24px;
  }

  .collapseHeading {
    font-size: 1rem !important;
  }

  .heading {
    font-family: sans-serif;
  }

  #gifPackSelect {
    z-index: 3;
  }

  .responseCard {
    box-shadow: 0 0 0.875rem 0 rgba(53, 64, 82, 0.7) !important;
  }

  #stickerPackSelect {
    z-index: 3;
  }

  .reorderContainer {
    width: 110px;
    height: 110px;
    display: inline-block;
 }

  .transliterationReorder {
    cursor: pointer;
    border-style: solid;
    border-color: #ced4da;
    background-color: #fff;
    border-radius: 0.2rem;
    margin-block: 8px;
    padding: 8px;
  }
